import React from 'react'

function ImageScrollToggle({ primaryImg, secondaryImg }) {

  const imageRef = React.useRef(null)

  const isInView = () => {
    const rect = imageRef.current.getBoundingClientRect()
    return rect.top >=0 && rect.bottom <= window.innerHeight
  }

  const [inView, setInView] = React.useState(false)

  const scrollHandler = () => {
    setInView(isInView())
  }

  React.useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  },[])

  return (
    <img src={inView ? secondaryImg : primaryImg}
        className="placeholder_img" 
        width="550" height="400"
        style={{objectFit : 'contain'}}
        alt="" ref={imageRef}
    />
  )
}

export default ImageScrollToggle