import { FaRunning, FaRegFileCode, FaRegGrinBeam } from 'react-icons/fa';


function Interests() {

  return (
    <section id="interests" className="interest-section">
      <h1 className="project-title" >My <span>Interests</span></h1>
      <hr className="line-style" /><br /><br />
      <div className="interest-container">
        <h4><FaRegFileCode className="interests"></FaRegFileCode>Coding and Building</h4>
        <p>I am currently working on side projects for people including Barbershop website and Educational site. I am using React to build this to hone the skills learnt at General Assembly. This is helping me gain experience in the development lifecycle from requirements, whiteboarding to the final product.</p>
        <h4><FaRunning className="interests"></FaRunning>Sports</h4>
        <p>I enjoy playing sports such as Cricket. Alongside this I constantly like to work on my fitness goals in Calisthenics and Sprinting to increase my speed and endurance.</p>
        <h4><FaRegGrinBeam className="interests"></FaRegGrinBeam>Martial Arts</h4>
        <p>Finally enjoy Boxing and Kickboxing, working towards my goal of black belt and learning the Art of Self-defense.
        </p>
      </div>
    </section>
  )
}

export default Interests