import React from 'react'
import projectOne from './assets/images/2048.png'
import superheroTopTrumps from './assets/images/superhero.png'
import moodflix from './assets/images/moodflix.png'
import eLearn from './assets/images/e-learn.png'
import projectOneGreyScaled from './assets/images/2048-greyscaled.png'
import projectTwoGrey from './assets/images/project-two-grey.png'
import ImageScrollToggle from './ImageScrollToggle'
import moodflixGrey from './assets/images/moodflix-grey.png'
import elearnGrey from './assets/images/e-learn-grey.png'



function Project() {
  //state to track if image inView(false by default)
  // const [inView, setInView] = React.useState(false)
  // //Track where on page the image is
  // const imageRef = React.useRef(null)


  // const isWithinView = () => {

  //   const rect = imageRef.current.getBoundingClientRect()
  //   return rect.top >=0 && rect.bottom <= window.innerHeight
  // }


  // React.useEffect(() => {
  //   setInView(isWithinView())
  //   window.addEventListener('scroll', scrollHandler)
  //   return () => {
  //     window.removeEventListener('scroll', scrollHandler)
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[])

  // const scrollHandler = () => {
  //   //Calculate whenever in view
  //   setInView(isWithinView())
  // }



  return (
    <>
      <section id="projects" className="project-section">
        <h1 className="project-title" >My <span>Projects</span></h1>
        <hr className="line-style" /><br /><br />
        <div className="row">
          <div className="column">
            <div className="blue-column">
              <ImageScrollToggle
                primaryImg={projectOneGreyScaled}
                secondaryImg={projectOne}
              />

            </div>
          </div>
          <div className="column">
            <div className="red-column">
              <h2><span className="project-title">Project 1</span> : 2048</h2>
              <p> Built a grid-based game using HTML, CSS and JavaScript. The project helped me solidify my understanding of JavaScript and applied problem solving to help deliver a fully functional game.</p>
              <div className="btn-container">

                <button className="contact-us-btn">
                  <a rel="noreferrer" target="_blank" className="link-styling" href="https://github.com/rizwanakhtar7/project-1">Readme Link</a>
                </button>
                <button className="contact-us-btn">
                  <a rel="noreferrer" target="_blank" className="link-styling" href="https://rizwanakhtar7.github.io/project-1/">Deployed Version</a>
                </button>

              </div>
            </div>
          </div>

        </div>

        <div className="row">
          <div className="column">
            <div className="red-column">
              <h2><span className="project-title">Project 2 :</span> Superhero Top Trumps</h2>
              <p>As a pair we spent two days building a React app using a Superhero open source API. This project help me work on team collaboration skills and get a feel of working together on the same task to deliver an end project in a short duration.</p>
                <div className="btn-container">

                  <button className="contact-us-btn">
                    <a rel="noreferrer" target="_blank" className="link-styling" href="https://github.com/rizwanakhtar7/sei-project-2">Readme Link</a>
                  </button>
                  <button className="contact-us-btn">
                    <a rel="noreferrer" target="_blank" className="link-styling" href="https://superherocards.netlify.app/">Deployed Version</a>
                  </button>
                </div>
            </div>
          </div>
          <div className="column">
            <div className="blue-column">
              <ImageScrollToggle
                primaryImg={projectTwoGrey}
                secondaryImg={superheroTopTrumps}
              />
            </div>
          </div>

        </div>

        <div className="row" >
          <div className="column">
            <div className="blue-column">
              <ImageScrollToggle
                primaryImg={moodflixGrey}
                secondaryImg={moodflix}
              />
            </div>
          </div>
          <div className="column">
            <div className="red-column">
              <h2><span className="project-title">Project 3 :</span> Moodflix</h2>
              <p> As a group of 3, we built an app to allow users to search / add movies by mood, also displaying movies by their ratings. Using MongoDB, Express and Node, I worked on the endpoint for displaying all the movies, movies by moods and the models for our application. I had to test this using client.http to make sure the requests were successfully bringing back the correct data. Later for the front-end using React and CSS, I spent half the day building the navbar for our applicaLon with AuthenLcaLon to make sure a logged in user can logout, navigate to secure routes. I moved onto working on the movies index page to display all movies by moods and giving the user the ability to search for a movie by mood or multiple moods.</p>
              <div className="btn-container">

                  <button className="contact-us-btn">
                    <a rel="noreferrer" target="_blank" className="link-styling" href="https://github.com/rizwanakhtar7/project-3-client">Readme Link</a>
                  </button>
                  <button className="contact-us-btn">
                    <a rel="noreferrer" target="_blank" className="link-styling" href="https://moodflix-site.netlify.app/">Deployed Version</a>
                  </button>
                </div>
            </div>
          </div>

        </div>

        <div className="row">
          <div className="column">
            <div className="red-column">
              <h2><span className="project-title">Project 4 :</span> E-Learn</h2>
              <p>As a pair we built an E-learning platform for instructors and learners in mulLple subjects. Using Django as a backend and PostgreSQL as a database, I was involved in configuring all the endpoints for our application and controllers to make sure the front-end consumes the correct data for courses and lessons. Additionally, I was involved in adding the model classes for the different tables in the database. Using React as a front-end, I worked on the home section, courses page to display all ratings, courses and link to lessons accordingly and CSS to style our application.</p>
              <div className="btn-container">

              <button className="contact-us-btn">
                <a rel="noreferrer" target="_blank" className="link-styling" href="https://github.com/rizwanakhtar7/project-4-server">Readme Link</a>
              </button>
              <button className="contact-us-btn">
                <a rel="noreferrer" target="_blank" className="link-styling" href="https://e-learn1.netlify.app/">Deployed Version</a>
              </button>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="blue-column">

              <ImageScrollToggle
                primaryImg={elearnGrey}
                secondaryImg={eLearn}
              />

            </div>
          </div>

        </div>

      </section>
    </>
  )
}

export default Project