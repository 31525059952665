import React from 'react'
import { animateScroll as scroll, Link } from 'react-scroll'
import { FaTimes, FaGripLines } from 'react-icons/fa';


function Nav() {
  const [showColor, setShowColor] = React.useState(false)
  
  // make responsive for mobiles
  const [click, setClick] = React.useState(false)
  const handleClick = () => setClick(!click)

  React.useEffect(() => {
    const scrollListener = () => {
      (window.scrollY > 120) ? setShowColor(true) : setShowColor(false)
    }
    window.addEventListener('scroll', scrollListener)
    return () => {
      window.removeEventListener('scroll', scrollListener)
    }
  }, [])

  return (
    <>
    <nav className={`${showColor ? 'navbar-show-color' : 'navbar-default-color'}`}>
      <button className="brand-logo" onClick={() => scroll.scrollToTop()} >R - A</button>
      <ul className={`${click ? 'nav-links active' : 'nav-links'}`}>
        <li className="nav-item"><Link to="about-me"smooth={true} duration={1500}>About Me</Link></li>
        <li className="nav-item"><Link to="skills"smooth={true} duration={1500}>My Skills</Link></li>
        <li className="nav-item"><Link to="projects"smooth={true} duration={1500}>Portfolios</Link></li>
        <li className="nav-item"><Link to="interests"smooth={true} duration={1500}>Interests</Link></li>

      </ul>
        <div className="menu-icon" onClick={handleClick}>
          {click ? <FaTimes className="fa-icon"/> : <FaGripLines className="fa-icon"/> } 
        </div>
      
    </nav>

    </>
  )
}

export default Nav