import portrait from './assets/images/selfie-of-me.jpeg'
import AOS from 'aos';
import 'aos/dist/aos.css';
import React from 'react';

function About() {
  React.useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <>
      <section id="about-me" className="about-section">
        <div className="about-us-container">
          <div className="grid-row" data-aos="slide-right">
            <img height="450" src={portrait} alt="selfie of me" />
          </div>
          <div className="grid-row text" data-aos="slide-left">
          <h1><span className="title-span">About</span> Me</h1>
          <hr className="line-style"/>
          <p>I have been a passionate problem solver ever since my first role, with a mission to work with and assist others along the way on the same journey path. When I joined Bluebay, I was supporting multiple teams as an Analyst and solving issues daily. Alongside my job role I started learning programming languages such as SQL, C# and shadowing development to get an understanding of real world application. This sparked my interest into the world of development and led me to join General Assembly as a Software immersive student. I am now looking to progress as a front-end or full-stack developer.</p>
          </div>
        </div>
      </section>

    </>
  )
}

export default About