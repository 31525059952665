function Skill() {
  return (
    <>
      <section className="skill-section" id="skills">
        <h1 className="skills-title">Skills</h1>
        <hr className="line-style"/><br /><br />
        <div className="skills-grid-main">
          <div className="skills-grid">
            <i className="devicon-html5-plain-wordmark colored"></i>
            <i className="devicon-css3-plain-wordmark colored"></i>
            <i className="devicon-javascript-plain colored"></i>
            <i className="devicon-react-original-wordmark colored"></i>
            <i className="devicon-sass-original colored"></i>
            <i className="devicon-bulma-plain colored"></i>
            <i className="devicon-bootstrap-plain-wordmark colored"></i>
          </div>
          <div className="skills-grid">
            <i className="devicon-react-original-wordmark colored"></i>
            <i className="devicon-express-original-wordmark colored"></i>
            <i className="devicon-nodejs-plain-wordmark colored"></i>
            <i className="devicon-mongodb-plain-wordmark colored"></i>
            <i className="devicon-postgresql-plain-wordmark colored"></i>
            <i className="devicon-django-line-wordmark colored"></i>
            <i className="devicon-java-plain-wordmark colored"></i>
          </div>
          <div className="skills-grid">
            <i className="devicon-git-plain-wordmark colored"></i>
            <i className="devicon-github-original-wordmark colored"></i>
            <i className="devicon-heroku-plain-wordmark colored"></i>
          </div>
        </div>
      </section>
    </>
  )
}

export default Skill