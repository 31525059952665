import About from '../About'
import Contact from '../Contact'
import Hero from '../Hero'
import Interests from '../Interests'
import Project from '../Project'
import Skill from '../Skill'

function Home() {
  return (
    <>
      <Hero />
      <About />
      <Skill />
      <Interests />
      <Project />
      <Contact />
      
    </>

  ) 
}

export default Home